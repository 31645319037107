/* 
Author: Justin Turbeville

Description: This component is the main register page of the app 
(linked in the navbar login). When user clicks the button, 
redux dispatch attempts to create a new user in firebase auth. 
If successful, user info is added to redux store and user is
redirected to a welcome page.
*/

import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { register } from "../../../setup/redux/slices/userSlice";
import Register from "../../../components/register/Register";

const NEW_CUSTOMER_URL = '/welcome-customer'
const NEW_MANUFACTURER_URL = '/welcome-manufacturer'

const NavRegister = () => {
    // hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    // local state
    const [form, setForm] = useState({ type: 'customer' })
    const setFormField = (field, value) => {
        setForm({...form, [field]: value})
    }
    const [errors, setErrors] = useState({});
    const setErrorsField = (field, value) => {
        setErrors(prevErrors => {
            return { ...prevErrors, [field]: value };
        });
    }
    const removeErrorsField = (field) => {
        setErrors((prevErrors) => {
            const { [field]: omit, ...newErrors } = prevErrors;
            return newErrors;
        })
    }

    /**
     * Check if email is valid. Set errors if necessary.
     * @returns boolean
     * true if email is valid, false otherwise
     */
    const checkEmail = () => {
        if (form.email) {
            return true;
        }
        return false;
    }

    /**
     * Check if passwords are valid. Set errors if necessary.
     * @returns boolean
     * true if email is valid, false otherwise
     */
    const checkPassword = () => {
        if (!(form.password && form.confirmPassword)) {
            return false;
        } else if (form.password !== form.confirmPassword) {
            setErrorsField('confirmPassword', 'Passwords do not match.');
            return false;
        }
        removeErrorsField('confirmPassword');
        return true;
    }

    const [disableButton, setDisableButton] = useState();

    /*
    Try calling redux async action 'register'. If user is
    created successfully, redirect to welcome page.
    */
    const handleCreate = async () => {
        dispatch(register({
            email: form.email,
            password: form.password,
            type: form.type 
        }))
            .then((response) => {
                const result = JSON.parse(response.payload)
                if(!result.hasOwnProperty('error')) {
                    navigate(getToPath(result.type))
                }
            })
    }

    useEffect(() => {
        const validEmail = checkEmail();
        const validPassword = checkPassword();
        if (!(validEmail && validPassword)) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [form])

    const getToPath = (type) => {
        if(location.state != null && location.state.hasOwnProperty('toPath')) {
            return location.state.toPath
        }
        else if(type === 'customer') {
            return NEW_CUSTOMER_URL
        }
        return NEW_MANUFACTURER_URL
    }

    return ( 
        <Container className="d-flex justify-content-center" style={{paddingTop:"4%"}}>
            <Card style={{width: "50%"}}>
                <Container style={{padding: "10%"}}>
                    <Register setField={setFormField} errors={errors} />
                    <Row style={{paddingTop: "8%"}}>
                        <Col className="d-flex justify-content-center">
                            <Button 
                                variant="primary" 
                                disabled={disableButton} 
                                onClick={() => handleCreate()}
                            >
                                Create Account
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </Container>
    )
}

export default NavRegister