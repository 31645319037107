import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './features/navigation/Navigation';
import InstantQuote from './features/instant-quote/InstantQuote';
import CustomerDashboard from './features/customer-dashboard/CustomerDashboard';
import ManufacturerDashboard from './features/manufacturer-dashboard/ManufacturerDashboard';
import NavLogin from './features/user/nav-login/NavLogin';
import NavRegister from './features/user/nav-register/NavRegister';
import WelcomeCustomer from './features/user/welcome-customer/WelcomeCustomer';
import WelcomeManufacturer from './features/user/welcome-manufacturer/WelcomeManufacturer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CompleteRegistration from './components/complete-registration/CompleteRegistration';
import SubmitOrder from './features/instant-quote/submit-order/SubmitOrder';
import CompleteOrderPayment from './features/instant-quote/complete-order-payment/CompleteOrderPayment';
import OrderSuccess from './features/instant-quote/order-success/OrderSuccess';

function App() {
	return (
		<div className="App">
		{/* <TestComp /> */}
			<BrowserRouter>
				<Navigation />
				<div className="page-contents">
					<Routes>
						<Route path="/" element={<InstantQuote />} /> 
						<Route path="/submit-order" element={<SubmitOrder />} />        
						<Route path="/login" element={<NavLogin />} />
						<Route path="/register" element={<NavRegister />} />
						<Route path="/complete-registration" element={<CompleteRegistration />} />
						<Route path="/welcome-customer" element={<WelcomeCustomer />} />
						<Route path="/welcome-manufacturer" element={<WelcomeManufacturer />} />
						<Route path="/customer-dashboard" element={<CustomerDashboard />} />
						<Route path="/manufacturer-dashboard" element={<ManufacturerDashboard />} />
						<Route path="/complete-order-payment" element={<CompleteOrderPayment />} />
						<Route path="/order-success" element={<OrderSuccess />} />
					</Routes>
				</div>
			</BrowserRouter>
		</div>
	);
}

export default App;