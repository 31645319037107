import { Container, Row, Col, Button, Accordion } from "react-bootstrap";
import { firestoreDB } from "../../../setup/firebase/firestoreDB";
import { collection, getDocs, where, query, doc, updateDoc, Timestamp } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../../setup/redux/slices/userSlice";
import { useSelector } from "react-redux";

const monthNums = { Jan: '1', Feb: '2', Mar: '3', Apr: '4',
                    May: '5', Jun: '6', Jul: '7', Aug: '8',
                    Sep: '9', Oct: '10', Nov: '11', Dec: '12' }

const timestampToDate = (timestamp) => {
    const dateArr = timestamp.toDate().toString().split(' ')
    return monthNums[dateArr[1]] + '/' +dateArr[2] + '/' + dateArr[3].slice(2)
}

const ManufacturerOrders = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState()
    const [aOrders, setAOrders] = useState()

    const user = useSelector(selectUser)

    const addOrder = (order, obj, setter) => {
        setter({ ...obj, [order.id]: order })
    }

    const removeOrder = (orderId, obj, setter) => {
        const tempObj = obj
        delete tempObj[orderId]
        setter(tempObj)
    }

    /**
     * 
     * @param {*} order 
     */
    const handleAcceptOrder = (order) => {
        const orderRef = doc(firestoreDB, 'orders', order.id)
        const acceptedTimestamp = Timestamp.now()
        updateDoc(orderRef, { 
            manufacturer: user.id,
            accepted: acceptedTimestamp
        })
        const acceptedDate = timestampToDate(acceptedTimestamp)
        removeOrder(order.id, aOrders, setAOrders)
        addOrder({
            ...order,
            acceptedDate: acceptedDate
        }, orders, setOrders)
    }

    const downloadCadFile = (orderId, isAvailableOrder) => {
        const order = isAvailableOrder ? aOrders[orderId] : orders[orderId];
        window.location.href = order.cadFileDownloadURL;
    };

    useEffect(() => {
        getDocs(query(collection(firestoreDB, 'orders'), where('manufacturer', 'in', ['', user.id])))
            .then((response) => {
                const tempOrders = {}
                const tempAOrders = {}
                response.forEach((order) => {
                    const createdDate = timestampToDate(order.data().created)
                    if(order.data().manufacturer == user.id) {
                        const acceptedDate = timestampToDate(order.data().accepted)
                        tempOrders[order.id] = { 
                            ...order.data(), 
                            id: order.id, 
                            createdDate: createdDate,
                            acceptedDate: acceptedDate 
                        }
                    }
                    else { //change this to 'else' once where is fixed
                        tempAOrders[order.id] = { 
                            ...order.data(), 
                            id: order.id, 
                            createdDate: createdDate 
                        }
                    }
                })
                setOrders(tempOrders)
                setAOrders(tempAOrders)
            })
            .catch((error) => {
                console.error(error.message)
            })
    }, [])

    return (
        <Container>
            <Accordion defaultActiveKey="machines-list" flush alwaysOpen>
                <Accordion.Item eventKey="add-machine">
                    <Accordion.Header>Available Jobs</Accordion.Header>
                        <Accordion.Body>
                            {   
                                aOrders && (Object.keys(aOrders).length > 0) &&
                                <Container>
                                    <Row>
                                        <Col/>
                                        <Col><strong>Name</strong></Col>
                                        <Col><strong>Qty</strong></Col>
                                        <Col><strong>Cost</strong></Col>
                                    </Row>
                                    <hr />
                                    {
                                        Object.keys(aOrders).map((id) => (
                                            <div key={id}>
                                                <Row>
                                                    <Col><Button onClick={() => handleAcceptOrder(aOrders[id])}>Accept</Button></Col>
                                                    <Col>
                                                        <p
                                                            className="link-primary"
                                                            onClick={() => downloadCadFile(id, true)}
                                                        >
                                                            {aOrders[id].productName}
                                                        </p>
                                                    </Col>
                                                    <Col>{aOrders[id].quantity}</Col>
                                                    <Col>${Math.round(aOrders[id].totalPrice*100)/100}</Col>
                                                </Row>
                                                <hr />
                                            </div>
                                        ))
                                    }
                                </Container> || <p>No jobs are available at this time.</p>
                            }
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="machines-list">
                    <Accordion.Header>Current Jobs</Accordion.Header>
                        <Accordion.Body>
                            {
                                orders && ((Object.keys(orders).length > 0) &&
                                <Container>
                                    <Row>
                                        <Col><strong>Name</strong></Col>
                                        <Col><strong>Status</strong></Col>
                                        <Col><strong>Accepted</strong></Col>
                                        <Col><strong>Qty</strong></Col>
                                        <Col><strong>Cost</strong></Col>
                                    </Row> 
                                    <hr />
                                    {
                                        Object.keys(orders).map((id) => (
                                            <div key={id}>
                                                <Row>
                                                    <Col>
                                                        <p
                                                            className="link-primary"
                                                            onClick={() => downloadCadFile(id, false)}
                                                        >
                                                            {orders[id].productName}
                                                        </p>
                                                    </Col>
                                                    <Col>{orders[id].status}</Col>
                                                    <Col>{orders[id].acceptedDate}</Col>
                                                    <Col>{orders[id].quantity}</Col>
                                                    <Col>${Math.round(orders[id].totalPrice*100)/100}</Col>
                                                </Row>
                                                <hr />
                                            </div>
                                        ))
                                    }
                                </Container>) || <p>You do not have any active jobs.</p>
                            }
                        </Accordion.Body>
                    
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}

export default ManufacturerOrders