import { Container, Row, Form } from "react-bootstrap"

// constants
const labels = {
    email: 'Email',
    password: 'Password'
}

const placeholders = {
    email: 'Email',
    password: 'Password'
}

const LoginForm = (props) => {
    // props
    const form = props.form
    const setFormField = props.setFormField
    const error = props.error

    return (
        <Container>
            <Form>
                <Row>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>{labels.email}</Form.Label>
                        <Form.Control 
                            type="email"
                            placeholder={placeholders.email} 
                            value={form.email}
                            isInvalid={error != null}
                            onChange={e => setFormField('email', e.target.value)} 
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>{labels.password}</Form.Label>
                        <Form.Control 
                            type="password" 
                            placeholder={placeholders.password}
                            value={form.password}
                            isInvalid={error != null}
                            onChange={e => setFormField('password', e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Form>
        </Container>
    )
}
 
export default LoginForm