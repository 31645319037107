import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const firebaseAuth = getAuth()

// Create Google Auth Provider
const googleAuthProvider = new GoogleAuthProvider();

// Initialize Firestore
const firestore = getFirestore();

// Initialize Firebase Storage
const firebaseStorage = getStorage();
// connectFirestoreEmulator(firestore, 'localhost', 8080)

// Initialize Firebase Functions
const firebaseFunctions = getFunctions();

// Export Firebase
export { 
    firebaseApp,
    firebaseAuth,
    googleAuthProvider,
    firestore,
    firebaseStorage,
    firebaseFunctions
};
