import { Row, Col, Form, Container } from "react-bootstrap";
import { statesArray } from "../../../../setup/values/constants";

// constants
const placeholders = {
    address: '1 Example RD',
    suite: '1A',
    city: 'Boston',
    postal: '00000'
}

const ShippingDetails = (props) => {
    // props
    const shipping = props.shipping
    const setShippingField = (field, value) => {
        props.setShippingField(field, value)
    }
    
    return ( 
        <Container>
            <Form>
                <Row className="shipping-row">
                    {/* Input for street address (E.g., 1 Example RD) */}
                    <Col xs={9}>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control 
                                value={shipping.address}
                                placeholder={placeholders.address || ''} 
                                onChange={e => setShippingField('address', e.target.value)} 
                            />
                        </Form.Group>   
                    </Col>
                    {/* Input for address suite (E.g., 1A) */}
                    <Col>
                        <Form.Group className="mb-3" controlId="suite">
                            <Form.Label>Suite</Form.Label>
                            <Form.Control 
                                value={shipping.suite || ''} 
                                placeholder={placeholders.suite} 
                                onChange={e => setShippingField('suite', e.target.value)} 
                            />
                        </Form.Group>   
                    </Col>
                </Row>
                <Row className="shipping-row">
                    {/* Input for city */}
                    <Col xs={6}>
                        <Form.Group className="mb-3" controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control 
                                value={shipping.city || ''} 
                                placeholder={placeholders.city}
                                onChange={e => setShippingField('city', e.target.value)} 
                            />
                        </Form.Group>   
                    </Col>
                    {/* Input for state */}
                    <Col xs={4}>
                        <Form.Group className="mb-3" controlId="state">
                            <Form.Label>State</Form.Label>
                            {/* Maybe populate using an array in the future? */}
                            <Form.Select 
                                defaultValue={shipping.state || ''} 
                                onChange={e => setShippingField('state', e.target.value)}
                            >
                                <option value="">Select a State</option>
                                { 
                                    statesArray.map((state) => (
                                        <option key={state.name} value={state.abbreviation}>
                                            {state.name}
                                        </option> 
                                    ))
                                }
                            </Form.Select>
                        </Form.Group> 
                    </Col>
                    {/* Input for postal code */}
                    <Col>
                        <Form.Group className="mb-3" controlId="zip">
                            <Form.Label>Postal</Form.Label>
                            <Form.Control 
                                type="text" 
                                pattern="[0-9]*" 
                                value={shipping.postal || ''} 
                                placeholder={placeholders.postal} 
                                onChange={e => setShippingField('postal', e.target.value)} 
                            />
                        </Form.Group>   
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default ShippingDetails