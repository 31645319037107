import { Container, Button, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const WelcomeManufacturer = () => {
    return ( 
        <div>
            <br/>
            <br/>
            <Container className="d-flex justify-content-center">
                <br/>
                <br/>
                <Card style={{width: "50%"}}>
                    <Container style={{padding: "10%"}}>
                        <Row style={{textAlign: "center"}}><h1>Welcome to Anvil!</h1></Row>
                        <br/>
                        <Row style={{textAlign: "center"}}><p>Click the button to manufacture.</p></Row>
                        <br/>
                        <Row style={{textAlign: "center"}}><Link to="/"><Button>Continue</Button></Link></Row>
                    </Container>
                </Card>
            </Container>
        </div>
    )
}

export default WelcomeManufacturer