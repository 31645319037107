import { useState } from "react";
import Dropzone from "react-dropzone";
import Loading from "../../../assets/svg/gear.svg";
import "./CadViewer.scss";
import { Component } from 'react';
import ViewerComponent from './viewer-component/viewer-component';
import Communicator from 'communicator';
import ModelTreeComponent from './model-tree-component/model-tree-component';

class CadRendering extends Component {
  constructor(props) {
    super(props);
    // Functions
    this.hwvReady = this.hwvReady.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeOperator = this.changeOperator.bind(this);
    // State
    this.state = {
      hwv: null,
      currentTab: 1, // 1: Home, 2: ModelTree
      cameraStatus: null,
      operator: 'Orbit',
      isStructureReady: false,
    };
  }

  // Callback when the new hwv is ready
  hwvReady(newHWV) {

    this.setState({
      hwv: newHWV,
    }, () => {
      this.state.hwv.setCallbacks({
        sceneReady: () => {
          this.setState({
            cameraStatus: this.state.hwv.view.getCamera().toJson(),
          });
        },
        modelStructureReady: () => {
          this.setState({
            isStructureReady: true,
          });
        },
        camera: () => {
          this.setState({
            cameraStatus: this.state.hwv.view.getCamera().toJson(),
          });
        }
      });
    });
  }

  changeOperator(event) {
    this.setState({
      operator: event.target.value,
    }, () => {
      if (!this.state.hwv) return;
      this.state.hwv.operatorManager.clear();
      this.state.hwv.operatorManager.push(Communicator.OperatorId.Orbit);
      if (this.state.operator === "Area Select") {
        this.state.hwv.operatorManager.push(Communicator.OperatorId.AreaSelect);
      } else if (this.state.operator === "Select") {
        this.state.hwv.operatorManager.push(Communicator.OperatorId.Select);
      } else if (this.state.operator === "Measure") {
        this.state.hwv.operatorManager.push(Communicator.OperatorId.MeasurePointPointDistance);
      }
    });
  }

  changeTab(newTab) {
    this.setState({
      currentTab: newTab,
    });
  }

  render() {
    const cameraStatusContent = this.state.cameraStatus == null ? <p>Unavailable</p> :
      <div>
        <p className="mb-0"><strong>Position: </strong>
          ({this.state.cameraStatus.position.x.toFixed(2)}, {this.state.cameraStatus.position.y.toFixed(2)}, {this.state.cameraStatus.position.z.toFixed(2)})
        </p>
        <p className="mb-0"><strong>Target: </strong>
          ({this.state.cameraStatus.target.x.toFixed(2)}, {this.state.cameraStatus.target.y.toFixed(2)}, {this.state.cameraStatus.target.z.toFixed(2)})
        </p>
        <p className="mb-0"><strong>Up: </strong>
          ({this.state.cameraStatus.up.x.toFixed(2)}, {this.state.cameraStatus.up.y.toFixed(2)}, {this.state.cameraStatus.up.z.toFixed(2)})
        </p>
        <p className="mb-0">
          <strong>Width: </strong> {this.state.cameraStatus.width.toFixed(2)} &nbsp;
        <strong>Height: </strong> {this.state.cameraStatus.height.toFixed(2)}
        </p>
        <p className="mb-0">
          <strong>Projection: </strong> {this.state.cameraStatus.projection.toFixed(2)} &nbsp;
        <strong>NearLimit: </strong> {this.state.cameraStatus.nearLimit.toFixed(2)}
        </p>
        <p className="mb-0"><strong>Class Name: </strong> {this.state.cameraStatus.className}</p>
      </div>;
    const homeTabContent = <div className={'tab-pane fade show ' + (this.state.currentTab === 1 ? 'active' : '')}>
      <h2>React Demo for Hoops Web Platform</h2>
      {/* Operator Selection */}
      <h5>Operator</h5>
      <select className="form-select mb-3" value={this.state.operator} onChange={this.changeOperator}>
        <option value="Orbit">Orbit</option>
        <option value="Area Select">Area Select</option>
        <option value="Select">Select</option>
        <option value="Measure">Measure</option>
      </select>
      {/* Camera Status */}
      <h5>Camera Status</h5>
      {cameraStatusContent}
    </div>;
    const modelStructureTabContent = <div className={'tab-pane fade show ' + (this.state.currentTab === 2 ? 'active' : '')}>
      <h5>Model Structure</h5>
      {
        this.state.isStructureReady
          ? <ModelTreeComponent hwv={this.state.hwv}></ModelTreeComponent>
          : <p>Model structure is not ready</p>
      }
    </div>;

    return (
      <div className="container-fluid p-0 m-0">
        <div className="row p-0 m-0" style={{ height: "80vh", width: "80vh" }}>
          {/* HWP WebViewer with Custom Component */}
            <ViewerComponent cadBuffer={this.props.cadBuffer} hwvReady={this.hwvReady}></ViewerComponent>
        </div > {/* Row End */}
      </div >
    );
  }
}

const CadViewer = (props) => {
    const cadBuffer = props.cadBuffer;
    const setCadBuffer = props.setCadBuffer;
    const setCadFile = props.setCadFile;

    const [fileUpload, setFileUpload] = useState();

    const convertFile = (file) => {
        let formData = new FormData();
        formData.append('file', file);
        fetch('https://converter-tech-soft-3d.ue.r.appspot.com/convert', {
            method: 'POST',
            body: formData,
        }).then(response => {
            return response.json();
        }).then(body => {
            let scsByteArray = new Uint8Array(body.scsFile.data);
            setCadBuffer(scsByteArray);
        }).catch(err => {
            console.log(err.message);
        });
    }

    const handleFile = (file) => {
        setCadFile(file);
        setFileUpload(true);
        convertFile(file);
    }

    return (
        <div>
            <Dropzone 
                onDrop={files => handleFile(files[0])}
                disabled={!!fileUpload}
            >
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="outer">
                                {
                                    !cadBuffer ?
                                    !fileUpload ?
                                    <div className="fill-parent">
                                        <p>Drop your CAD file here!</p>
                                    </div> :
                                    <img src={Loading} /> :
                                    <div className="fill-parent"><CadRendering cadBuffer={cadBuffer} /></div>
                                }
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>
        </div>
    );
}
 
export default CadViewer;