import { Container, Row, Col, Form, CloseButton } from "react-bootstrap";
import "./ProductCustomization.css";

const ProductCustomization = (props) => {
    // props
    const product = props.product
    const setProductField = props.setProductField;
    const order = props.order
    const unitPrice = order.unitPrice || 0
    const totalPrice = order.totalPrice || 0
    const quantity = order.quantity || 0

    // funtions
    const setQuantity = (value) => {
        props.setOrderField('quantity', Number.parseInt(value))
    }

    const removeProductField = (field) => {
        props.removeProductField(field)
    }

    return (
        <Container>
            <h1>Unit Price: ${Math.round(unitPrice * 100) / 100}, Total: ${Math.round(totalPrice * 100) / 100}</h1>
            <Row className="customization-row">
                <Col xs={2} className="customization-label">Name:</Col>
                <Col xs={10}>
                    <Form.Control 
                        type="text" 
                        value={product.name}
                        onChange={(e) => setProductField('name', e.target.value)}
                    />
                </Col>
            </Row>
            {
                product.material &&
                <Row className="customization-row">
                    <Col xs={4} className="customization-label">Material:</Col>
                    <Col><strong>{product.material.name}</strong></Col>
                    <Col xs={1}><CloseButton onClick={() => removeProductField('material')}/></Col>
                </Row>
            }
            {
                product.processes &&
                <Row className="customization-row">
                    <Col xs={4} className="customization-label">Process:</Col>
                    <Col><strong>{product.processes.name}</strong></Col>
                    <Col xs={1}><CloseButton onClick={() => removeProductField('processes')}/></Col>
                </Row>
            }
            {
                product.finish &&
                <Row className="customization-row">
                    <Col xs={4} className="customization-label">Finish:</Col>
                    <Col><strong>{product.finish.name}</strong></Col>
                    <Col xs={1}><CloseButton onClick={() => removeProductField('finish')}/></Col>
                </Row>
            }
            {
                product.testing &&
                <Row className="customization-row">
                    <Col xs={4} className="customization-label">Testing:</Col>
                    <Col><strong>{product.testing.name}</strong></Col>
                    <Col xs={1}><CloseButton onClick={() => removeProductField('testing')}/></Col>
                </Row>
            }
            {
                product.packaging &&
                <Row className="customization-row">
                    <Col xs={4} className="customization-label">Packaging:</Col>
                    <Col><strong>{product.packaging.name}</strong></Col>
                    <Col xs={1}><CloseButton onClick={() => removeProductField('packaging')}/></Col>
                </Row>
            }
            {
                product.components &&
                <Row className="customization-row">
                    <Col xs={4} className="customization-label">Components:</Col>
                    <Col><strong>{product.components.name}</strong></Col>
                    <Col xs={1}><CloseButton onClick={() => removeProductField('components')}/></Col>
                </Row>
            }
            <Row className="customization-row">
                <Col xs={2} className="customization-label">Quantity:</Col>
                <Col xs={2}>
                    <Form.Control 
                        type="number" 
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}/>
                </Col>
            </Row>
        </Container>
    )
}

export default ProductCustomization