import { Button, Container, Row } from "react-bootstrap"
import { useState } from "react"
import CompleteRegistrationForm from "../complete-registration-form/CompleteRegistrationForm"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { firebaseAuth } from "../../setup/firebase/firebase"
import { createFirestoreUser } from "../../setup/redux/slices/userSlice"

// constants
const REGISTER_URL = '/register'

const CompleteRegistration = () => {
    // hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // local state
    const [form, setForm] = useState({ type: 'customer' })
    const setFormField = (field, value) => {
        setForm({ ...form, [field]: value })
    }

    // functions
    const handleCreateAccount = () => {
        if(firebaseAuth.currentUser != null) {
            dispatch(createFirestoreUser({ type: form.type }))
                .then((response) => {
                    const result = JSON.parse(response.payload)
                    if(!result.hasOwnProperty('error')) {
                        navigate('/customer-dashboard')
                    }
                    else {
                        handleError()
                    }
                })
        }
        else {
            navigate(REGISTER_URL)
        }
    }

    const handleError = () => {
        console.log('error')
    }

    return (
        <Container>
            <Row>
                <h3>Finish Creating Your Account</h3>
            </Row>
            <Row>
                <CompleteRegistrationForm setFormField={setFormField} />
            </Row>
            <Row>
                <Button onClick={() => handleCreateAccount()}>Create Account</Button>
            </Row>
        </Container>
    )
}
 
export default CompleteRegistration