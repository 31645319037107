import { firebaseApp } from './firebase'
import { getFirestore, getDocs, collection } from "firebase/firestore";

// Initialize Firestore
export const firestoreDB = getFirestore(firebaseApp)

/* 
Read docs from collection and return array 
@params
colName: name of the firestore collection
@return
array of objects
*/
export const getDocsArray = async (colName) => {
    return getDocs(collection(firestoreDB, colName))
        .then((result) => {
            const docs = []
            result.docs.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id })
            })
            return docs
        })
        .catch((error) => {
            throw new Error(error.message)
        })
}

/*
Reads a collection from firestore, sorting the
docs into arrays based on specified field. Arrays
are added to an object such that the key of each
array is the sort field value.
@params
colName: name of firestore collection
sortField: the name of the [document] field used
to sort the docs
typesArray: array of possible sortField values
to be used as keys in the return object
@return
dictionary
*/
export const getSortedDocsObj = async (colName, sortField, typesArray) => {
    return getDocs(collection(firestoreDB, colName))
        .then((response) => {
            const sortedDocs = {}
            typesArray.forEach((type) => {
                sortedDocs[type] = []
            })
            response.docs.forEach((doc) => {
                sortedDocs[doc.data()[sortField]].push({ ...doc.data(), id: doc.id })
            })
            return sortedDocs
        })
        .catch((error) => {
            throw new Error(error.message)
        })
}