/*
Author: Justin Turbeville

Description: Component serves as the apps navigation bar.
Navigation links:
- Logo
- Dashboard
- Login/Logout
*/

import { Navbar, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { logout, selectUser } from '../../setup/redux/slices/userSlice';
import { firebaseAuth } from '../../setup/firebase/firebase';
import Logo from '../../assets/brand/logo.png'
import './Navigation.css'

const Navigation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(selectUser)

    const handleLoginClick = () => {
        let url = '/'
        if(firebaseAuth.currentUser != null) {
            dispatch(logout())
        }
        else {
            url += 'login'
        }
        navigate(url)
    }

    return ( 
        <Navbar className="py-3" bg="light" expand="lg">
            <Link to="/">
                <Navbar.Brand id="navbar-brand">
                    <img
                        src={Logo}
                        width="80"
                        height="80"
                        className="d-inline-block align-top"
                        alt="Anvil"
                    />
                </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
                <Nav className="flex-grow-1 justify-content-end">
                    <Nav.Link 
                        className="site-nav-link" 
                        onClick={() => navigate('/')}
                    >
                        Instant Quote
                    </Nav.Link>
                    <Nav.Link 
                        className="site-nav-link" 
                        onClick={() => navigate(user.hasOwnProperty('type') ? '/' + user.type + '-dashboard' : '/login')}
                    >
                        Dashboard
                    </Nav.Link>
                    <Nav.Link 
                        className="site-nav-link" 
                        onClick={() => handleLoginClick()}
                    >
                        {user.hasOwnProperty('id') ? 'Log Out' : 'Log In'}
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation