import { Container, Form } from "react-bootstrap";

const CompleteRegistrationForm = (props) => {
    const setFormField = props.setFormField
    return (
        <Container>
            <Form>
                <Form.Group className="mb-3" controlId="user-account-type">
                    <Form.Label>Account Type</Form.Label>
                    <Form.Select onChange={e => setFormField('type', e.target.value)}>
                        <option value="customer">Customer</option>
                        <option value="manufacturer">Manufacturer</option>
                    </Form.Select>
                </Form.Group>
            </Form>
        </Container>
    )
}
 
export default CompleteRegistrationForm;