import { useEffect, useState } from "react"
import { deleteDoc, doc, Timestamp } from "firebase/firestore"
import { Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import { collection, addDoc } from "firebase/firestore"
import { firebaseFunctions, firebaseStorage, firestore } from "../../../setup/firebase/firebase"
import { ref, getDownloadURL, uploadBytes, listAll, deleteObject } from "firebase/storage";
import { selectUser } from "../../../setup/redux/slices/userSlice"
import ShippingDetails from "./shipping-details/ShippingDetails"
import './SubmitOrder.scss'
import { httpsCallable } from "firebase/functions"
import OrderPaymentForm from "../../../components/order-payment-form/OrderPaymentForm"
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// constants
const LOGIN_PATH = '/login'
const SUBMIT_ORDER_PATH = '/submit-order'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const createPaymentIntent = httpsCallable(firebaseFunctions, 'createPaymentIntent');

const SubmitOrder = () => {
    const [clientSecret, setClientSecret] = useState("");
    const location = useLocation();
    const order = location.state.order;
    const totalPrice = order.totalPrice * 100;
    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        createPaymentIntent({ amount: totalPrice }).then(res => {
            setClientSecret(res.data.clientSecret);
        }).catch(error => {
            console.log(error.message);
        })
    }, [totalPrice]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    // local state
    const [shipping, setShipping] = useState(JSON.parse(sessionStorage.getItem('shipping')) || {})
    const setShippingField = (field, value) => {
        setShipping({ ...shipping, [field]: value })
    }
    useEffect(() => {
        sessionStorage.setItem('shipping', JSON.stringify(shipping))
    }, [shipping]);

    const user = useSelector(selectUser)
    const product = location.state.product;
    const cadFile = location.state.cadFile;
    const scsBuffer = location.state.scsBuffer;

    const handleSubmitOrder = () => {
        const finalOrder = {
            ...order,
            shipping: shipping,
            customer: user.id,
            manufacturer: '',
            created: Timestamp.now(),
            status: 'Waiting'
        };

        let result = {};
        return addDoc(collection(firestore, 'products'), product).then(async (response) => {
            result.productId = response.id;
            finalOrder.product = response.id;
            const cadFileNameArr = cadFile.name.split('.');
            const cadFileExtension = cadFileNameArr[cadFileNameArr.length - 1];
            finalOrder.productName = product.name;
            finalOrder.cadFileName = product.name + '.' + cadFileExtension;
            const path = 'orders/' + response.id + '/';
            const filename = product.name.replace(/[/\\?%*:|"<> ]/g, '-');
            const cadFileStorageRef = ref(firebaseStorage, path + filename + '.' + cadFileExtension);
            const scsFileStorageRef = ref(firebaseStorage, path + filename + '.scs');
            await uploadBytes(cadFileStorageRef, cadFile);
            uploadBytes(scsFileStorageRef, scsBuffer);
            return cadFileStorageRef;
        }).then(storageRef => {
            return getDownloadURL(storageRef);
        }).then(downloadURL => {
            finalOrder.cadFileDownloadURL = downloadURL;
            return addDoc(collection(firestore, 'orders'), finalOrder);
        }).then((response) => {
            if (response.id) {
                result.orderId = response.id;
                return result;
            }
        }).catch(err => {
            result.error = err.message;
            return result;
        });
    }

    const rollbackOrder = (ids) => {
        if (ids.productId) {
            deleteDoc(doc(firestore, 'products', ids.productId)).then(() => {
                const storageRef = ref(firebaseStorage, 'orders/' + ids.productId);
                return listAll(storageRef)
            }).then(res => {
                res.items.forEach(fileRef => {
                    deleteObject(fileRef);
                });
            }).catch(err => {
                console.log(err.message);
            })
        }
        if (ids.orderId) {
            deleteDoc(doc(firestore, 'orders', ids.orderId));
        }
    }

    return (
        <div>
            {
                // validate order and navigate back to InstantQuote if necessary
                !user.hasOwnProperty('id') &&
                <Navigate to={LOGIN_PATH} state={{ toPath: SUBMIT_ORDER_PATH }} />
            }
            {
                user.hasOwnProperty('id') &&
                <Container className="accordion-outer-container">
                    <Container>
                    <Row>
                        <h3>Shipping</h3>
                    </Row>
                    <Row>
                        <ShippingDetails 
                            shipping={shipping}
                            setShippingField={setShippingField}
                        />
                    </Row>
                    </Container>
                    <Container>
                        <Row>
                            <h3>Payment</h3>
                        </Row>
                        <Row>
                            {
                                clientSecret && 
                                <Elements options={options} stripe={stripePromise}>
                                    <OrderPaymentForm
                                        handleSubmitOrder={handleSubmitOrder}
                                        rollbackOrder={rollbackOrder}
                                    />
                                </Elements>
                            }
                        </Row>
                    </Container>
                </Container>
            }
        </div>
    )
}
 
export default SubmitOrder