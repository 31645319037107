import { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const OrderSuccess = () => {
    useEffect(() => {
        window.addEventListener('beforeunload', (e) => {
            e.preventDefault();
            e.returnValue = '';
        });
        return () => {
            window.removeEventListener('beforeunload');
        }
      }, [])
    /**
     * Remove items from local storage so order 
     * is not rolled back on session end
     */
    localStorage.removeItem('orderId');
    localStorage.removeItem('deleteProduct');
    const navigate = useNavigate()

    const handleNext = () => {
        navigate('/customer-dashboard');
    }
    return (
        <Container>
            <Button onClick={() => handleNext()}>Next</Button>
            <Button onClick={() => localStorage.setItem('test', 'TEST')}>TEST</Button>
        </Container>
    );
}
 
export default OrderSuccess;