import { Tabs, Tab } from 'react-bootstrap';
import ManufacturerOrders from './manufacturer-orders/ManufacturerOrders';
import ManufacturerMachines from './manufacturer-machines/ManufacturerMachines';
import { useSelector } from 'react-redux';
import { selectUser } from '../../setup/redux/slices/userSlice';
import { Navigate } from 'react-router-dom';

const ManufacturerDashboard = () => {
    const user = useSelector(selectUser)

    return ( 
        <div>
            {!user.hasOwnProperty('id') && <Navigate to={'/login'} />}
            {
                user.hasOwnProperty('id') &&
                <Tabs defaultActiveKey="jobs" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="jobs" title="Jobs">
                        <ManufacturerOrders />
                    </Tab>
                    <Tab eventKey="factory" title="Factory">
                        <ManufacturerMachines />
                    </Tab>
                </Tabs>
            }
        </div>
    )
}

export default ManufacturerDashboard