import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Accordion } from "react-bootstrap";
import { firestoreDB } from "../../../setup/firebase/firestoreDB";
import { collection, getDocs, where, addDoc, query } from "firebase/firestore";
import { useSelector } from "react-redux/es/exports";
import { selectUser } from "../../../setup/redux/slices/userSlice";

const placeholders = {
    name: '3D printer 123',
    cost: 0.00
}

const ManufacturerMachines = () => {
    const user = useSelector(selectUser)
    const [machines, setMachines] = useState()
    const [machineInfo, setMachineInfo] = useState({})

    const setField = (field, value) => {
        setMachineInfo({ ...machineInfo, [field]: value })
    }

    const addMachine = (machine) => {
        setMachines({ ...machines, [machine.id]: machine })
    }

    const handleAddMachine = () => {
        const machine = { 
            ...machineInfo, 
            manufacturer: user.id, 
            status: 'Idle', 
            order: null 
        }

        addDoc(collection(firestoreDB, 'machines'), machine)
            .then((response) => {
                addMachine({ ...machine, id: response.id })
                setMachineInfo({})
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    useEffect(() => {
        getDocs(query(collection(firestoreDB, 'machines'), where('manufacturer', '==', user.id)))
            .then((result) => {
                const tempMachines = {}
                result.docs.forEach((machine) => {
                    tempMachines[machine.id] = { ...machine.data(), id: machine.id }
                })
                setMachines(tempMachines)
            })
    }, [])

    return ( 
        <Container>
            <Accordion defaultActiveKey="machines-list" flush alwaysOpen>
                <Accordion.Item eventKey="add-machine">
                    <Accordion.Header>Add a Machine</Accordion.Header>
                    <Accordion.Body>
                        <Form style={{width: "67%"}}>
                            <Row className="d-flex justify-content-center">
                                <Col><br/><Button onClick={() => handleAddMachine()}>Add Machine</Button></Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control value={machineInfo.name || ''}
                                                        placeholder={placeholders.name} 
                                                        onChange={e => setField('name', e.target.value)} 
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="manufacturing-process">
                                        <Form.Label>Manufacturing Process</Form.Label>
                                        <Form.Select value={machineInfo.process || ''} 
                                                        onChange={e => setField('process', e.target.value)}
                                        >
                                            {/* Populate options from manufacturer capabilities */}
                                            <option value="">Select a Process</option>
                                            <option value="3D Printing">3D Printing</option>
                                            <option value="CNC Machining">CNC Machining</option>
                                            <option value="Injection Molding">Injection Molding</option>
                                        </Form.Select>
                                    </Form.Group> 
                                </Col>
                                <Col xs={2}>
                                    <Form.Group className="mb-3" controlId="cost">
                                        <Form.Label>Cost Per Hour</Form.Label>
                                        <Form.Control type='number' 
                                                        value={machineInfo.cost || ''}
                                                        placeholder={placeholders.cost} 
                                                        onChange={e => setField('cost', e.target.value)} 
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="machines-list">
                    <Accordion.Header>Machines</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col><strong>Name</strong></Col>
                            <Col><strong>Status</strong></Col>
                            <Col><strong>Job Id</strong></Col>
                        </Row>
                        <hr />
                        {
                            machines &&
                            Object.keys(machines).map((id) => (
                                <div key={id}>
                                    <Row>
                                        <Col>{machines[id].name}</Col>
                                        <Col>{machines[id].status}</Col>
                                        <Col>{machines[id].order == null ? '-' : machines[id].order}</Col>
                                    </Row>
                                    <hr />
                                </div>
                            ))
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container> 
    )
}

export default ManufacturerMachines