import { Container, Row, Form } from "react-bootstrap"

export const Register = (props) => {
    const setField = props.setField;
    const errors = props.errors;

    return (
        <Container>
            <Row><h1>Create Your Account</h1></Row>
            <Row><p>Create an account to view and manage your projects.</p></Row>
            <Form style={{paddingTop: "3%"}}>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Enter email"
                        onChange={e => setField('email', e.target.value)} 
                    />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control 
                        type="password"
                        placeholder="Password"
                        onChange={e => setField('password', e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Re-enter Password"
                        isInvalid={!!errors.confirmPassword}
                        feedback={errors.confirmPassword}
                        onChange={e => setField('confirmPassword',
                        e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="user-account-type">
                    <Form.Label>Account Type</Form.Label>
                    <Form.Select onLoad={e => setField('type', e.target.value)} onChange={e => setField('type', e.target.value)}>
                        <option value="customer">Customer</option>
                        <option value="manufacturer">Manufacturer</option>
                    </Form.Select>
                </Form.Group>
            </Form>
        </Container>
    )
}

export default Register;