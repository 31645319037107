import './InstantQuote.scss';
import { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ProductCustomization from "./product-customization/ProductCustomization";
import CustomizationNavbar from "./customization-navbar/CustomizationNavbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../setup/redux/slices/userSlice";
import CadViewer from "./cad-viewer/CadViewer";

// constants
const SUBMIT_ORDER_PATH = '/submit-order';
const LOGIN_PATH = '/login';
const MISSING_ITEMS_MESSAGE = 'Required items missing: ';
const REQUIRED_PRODUCT_FIELDS = [
    'name',
    'material',
    'processes',
];
const REQUIRED_ORDER_FIELDS = [
    'quantity',
];

const InstantQuote = () => {
    // hooks
    const navigate = useNavigate();

    // global state
    const user = useSelector(selectUser);

    // local state
    // store information related to the order
    const [order, setOrder] = useState({});
    const setOrderField = (field, value) => {
        setOrder(curOrder => { return { ...curOrder, [field]: value }});
    }

    // store information related to the product
    const [product, setProduct] = useState(JSON.parse(sessionStorage.getItem('product') || '{}' ));
    const setProductField = (field, value) => {
        setProduct({ ...product, [field]: value });
    }
    const removeProductField = (field) => {
        setProduct((curProduct) => {
            const { [field]: omit, ...newProduct } = curProduct;
            return newProduct;
        });
    }

    // store product details --> session storage
    useEffect(() => {
        sessionStorage.setItem('product', JSON.stringify(product));
    }, [product]);
    // CAD File
    const [cadFile, setCadFile] = useState();

    // .scs file data as Uint8 byte array
    const [cadBuffer, setCadBuffer] = useState();

    // page error message to be displayed
    const [errorMessage, setErrorMessage] = useState();

    // returns array of required fields that are missing
    const missingRequiredFields = () => {
        const missingFields = [];
        const orderKeys = Object.keys(order);
        // check for missing order fields
        REQUIRED_ORDER_FIELDS.forEach(field => {
            if (!orderKeys.includes(field)) {
                missingFields.push(field);
            }
        });
        // check for missing product fields
        const productKeys = Object.keys(product);
        REQUIRED_PRODUCT_FIELDS.forEach(field => {
            if (!productKeys.includes(field)) {
                missingFields.push(field);
            }
        });
        // check if CAD file is missing
        if (!cadFile) {
            missingFields.push('CAD File');
        }
        return missingFields;
    }
    
    // function is called when user clicks submit button
    const handleSubmit = () => {
        const missingFields = missingRequiredFields();
        // if no fields are missing
        if (missingFields.length == 0) {
            // if user is logged in
            if(user.hasOwnProperty('id')) {
                // navigate to payment page and pass order + product details
                navigate(SUBMIT_ORDER_PATH, { 
                    state: {
                        order: order,
                        product: product,
                        cadFile: cadFile,
                        scsBuffer: cadBuffer,
                    } 
                });
            }
            // if user is not logged in, redirect to login
            else {
                navigate(LOGIN_PATH, { state: { toPath: SUBMIT_ORDER_PATH } })
            }
        // if fields are missing, display the fields in error message
        } else {
            let tempErrorMessage = MISSING_ITEMS_MESSAGE + missingFields[0];
            missingFields.slice(1).forEach(field => {
                tempErrorMessage += ', ' + field;
            })
            setErrorMessage(tempErrorMessage);
        }
    }
    
    //calculates new price when specs are changed
    useEffect(() => {
        const tempUnitPrice = (product.hasOwnProperty('processes') ? product.processes.cost : 0) +
                                (product.hasOwnProperty('finish') ? product.finish.cost : 0) + 
                                (product.hasOwnProperty('testing') ? product.testing.cost : 0) + 
                                (product.hasOwnProperty('packaging') ? product.packaging.cost : 0) + 
                                (product.hasOwnProperty('components') ? product.components.cost : 0);
        setOrderField('unitPrice', tempUnitPrice)
        setOrderField('totalPrice', tempUnitPrice*(order.quantity || 0))
    }, [product])
    
    // update total price when user changes quantity
    useEffect(() => {
        setOrderField('totalPrice', order.unitPrice*order.quantity)
    }, [order.quantity])

    return (
        <div className="instant-quote">
            <CustomizationNavbar setProductField={setProductField} />
            <br />
            <br />
            {/* All content below the part customization navbar */}
            <Container id="main-interface">
                <Row>
                    {/* Left column displays current selection of customizations (material, processes, etc.) */}
                    <Col id="part-info-screen" md={true}>
                        <ProductCustomization 
                            product={product}
                            setProductField={setProductField}
                            removeProductField={removeProductField}
                            order={order}
                            setOrderField={setOrderField}
                        />
                        <br />
                        <Container>
                            {
                                errorMessage &&
                                <Row>
                                    <p className="error-message">{errorMessage}</p>
                                </Row>
                            }
                            <Row className="part-info-row">
                                <Col className="d-flex justify-content-center">
                                    <Button id="submit-button" onClick={() => handleSubmit()}>Submit</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    {/* Right column shows 3D model */}
                    <Col id="part-stl-viewer" md={true}>
                        <div className="d-flex justify-content-center">
                            <CadViewer 
                                cadBuffer={cadBuffer}
                                setCadBuffer={setCadBuffer}
                                setCadFile={setCadFile}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>           
    )
}

export default InstantQuote