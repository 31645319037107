import { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { selectUser } from "../../../setup/redux/slices/userSlice";
import { firestoreDB } from "../../../setup/firebase/firestoreDB";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Container, Row, Col } from "react-bootstrap";

const monthNums = { Jan: '1', Feb: '2', Mar: '3', Apr: '4',
                        May: '5', Jun: '6', Jul: '7', Aug: '8',
                        Sep: '9', Oct: '10', Nov: '11', Dec: '12' }

const CustomerOrders = () => {
    const user = useSelector(selectUser)

    const [orders, setOrders] = useState()
    const downloadCadFile = (cadFileDownloadURL) => {
        window.location.href = cadFileDownloadURL;
    }
    useEffect(() => {
        getDocs(query(collection(firestoreDB, 'orders'), where('customer', '==', user.id)))
            .then((response) => {
                const tempOrders = []
                response.docs.forEach((order) => {
                    const createdArr = order.data().created.toDate().toString().split(' ')
                    const created = monthNums[createdArr[1]]+'/'+createdArr[2]+'/'+createdArr[3].slice(2)
                    tempOrders.push({ ...order.data(), id: order.id, createdDate: created })
                })
                setOrders(tempOrders)
            })
            .catch((error) => {
                console.error(error.message)
            })
    }, [])

    return ( 
        <Container className="customer-dashboard">
            <h1>Orders</h1>
            <hr />
            <Row>
                <Col><strong>Product</strong></Col>
                <Col><strong>Status</strong></Col>
                <Col><strong>Created</strong></Col>
                <Col><strong>Qty</strong></Col>
                <Col><strong>Cost</strong></Col>
            </Row>
            <hr />
            {
                orders &&
                orders.map((order) => (
                    <div key={ order.id }>
                        <Row>
                            <Col>
                                <p
                                    className="link-primary"
                                    onClick={() => downloadCadFile(order.cadFileDownloadURL)}
                                >
                                    {order.productName}
                                </p>
                            </Col>
                            <Col>{order.status}</Col>
                            <Col>{order.createdDate}</Col>
                            <Col>{order.quantity}</Col>
                            <Col>${Math.round(order.totalPrice * 100)/100}</Col>
                        </Row>
                        <hr />
                    </div>
                ))
            }
        </Container>
    )
}

export default CustomerOrders;