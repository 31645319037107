import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import OrderPaymentForm from "../../../components/order-payment-form/OrderPaymentForm";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../setup/firebase/firebase";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51M9gDTAhrL9EzAXixCffBiVuTwrokcQVQH0SUh9temkwKSPillzkQQf0yBZ7gpvO1Jy9cxLbQNZPTP1pYD7EW35p00fm1eWixK");
const createPaymentIntent = httpsCallable(firebaseFunctions, 'createPaymentIntent');

const CompleteOrderPayment = () => {
    const [clientSecret, setClientSecret] = useState("");
    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        createPaymentIntent({ amount: 10000000 }).then(res => {
            setClientSecret(res.data.clientSecret);
        }).catch(error => {
            console.log(error.message);
        })
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className="App">
            {
                clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <OrderPaymentForm />
                    </Elements>
                )
            }
        </div>
    );
}
 
export default CompleteOrderPayment;