import "./NavLogin.scss"
import { Container, Row, Col, Button, Card } from "react-bootstrap"
import { useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { googleAuthProvider } from "../../../setup/firebase/firebase"
import { useDispatch } from "react-redux"
import { login, loginWithPopup } from "../../../setup/redux/slices/userSlice"
import LoginForm from "../../../components/login-form/LoginForm"
import GoogleButton from "react-google-button"

// constants
const CUSTOMER_URL = '/customer-dashboard'
const MANUFACTURER_URL = '/manufacturer-dashboard'
const REGISTER_URL = '/register'
const NEW_GOOGLE_USER_ERROR = 'Firebase Auth user does not have a corresponding user doc in Firestore.'

const NavLogin = (props) => { 
    // props
    const setIsNewGoogleUser = props.setIsNewGoogleUser

    // hooks
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // local state
    
    const [form, setForm] = useState({ email: '', password: '' })
    const setFormField = (field, value) => {
        setForm({ ...form, [field]: value })
    }

    const [errorMessage, setErrorMessage] = useState()

    // functions
    const handleLoginWithEmail = () => {
        dispatch(login({ email: form.email, password: form.password }))
            .then((response) => {
                const result = JSON.parse(response.payload)
                if(!result.hasOwnProperty('error')) {
                    navigate(getToPath(result.type))
                } else {
                    handleError(result.error)
                }
            })
            .catch((error) => {
                handleError(error.message)
            })
    }

    const handleLoginWithGoogle = () => {
        dispatch(loginWithPopup(googleAuthProvider))
            .then((response) => {
                const result = JSON.parse(response.payload)
                if(!result.hasOwnProperty('error')) {
                    navigate(getToPath(result.type));
                } else {
                    console.log(result.error);
                }
            })
            .catch((error) => {
                handleError(error.message)
            })
    }

    const handleError = (error) => {
        console.log(error)
        switch (error) {
            case NEW_GOOGLE_USER_ERROR:
                handleNewGoogleUser()
        }
    }

    const handleNewGoogleUser = () => {
        setIsNewGoogleUser(true)
    }

    const getToPath = (type) => {
        if(location.state != null && location.state.hasOwnProperty('toPath')) {
            return location.state.toPath
        }
        else if(type == 'customer') {
            return CUSTOMER_URL
        }
        return MANUFACTURER_URL
    }

    return ( 
        <Container className="d-flex justify-content-center login-outer-container">
            <Card className="login-card">
                <Container className="login-inner-container">
                    <Row><h1>Login</h1></Row>
                    <Row className="vertical-spacing">
                        <LoginForm
                            form={form}
                            setFormField={setFormField}
                            error={errorMessage}
                        />
                    </Row>
                    <Row className="d-flex justify-content-center vertical-spacing">
                        <Button className="login-button" onClick={() => handleLoginWithEmail()}>Login</Button>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center vertical-spacing">
                            <Link to="">Forgot Your Password</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Link to={REGISTER_URL} state={location.state}>Create Your Account</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center vertical-spacing">
                            <GoogleButton onClick={() => handleLoginWithGoogle()} />
                        </Col>
                    </Row>
                </Container>  
            </Card>
        </Container>
    )
}
 
export default NavLogin