import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { firestoreDB } from "../../firebase/firestoreDB";
import { firebaseAuth, googleAuthProvider } from "../../firebase/firebase";
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signInWithPopup, 
    signOut 
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { reduxGoogleUserNotInFirestore } from "../../values/constants/errors";

const initialState = {}

/**
 * Create a user in Firebase Auth
 * Creates a user in Firestore
 * Adds user/error to Redux store
 * @param {object} user - {email, password, type}
 * @return {JSON String} - { email, password, type } or { error }
 */
export const register = createAsyncThunk(
    'user/register',
    async (user) => {
        return createUserWithEmailAndPassword(firebaseAuth, user.email, user.password)
            .then((response) => {
                const userData = { type: user.type, email: user.email }
                setDoc(doc(firestoreDB, 'users', response.user.uid), userData)
                return JSON.stringify({
                    type: user.type,
                    id: response.user.uid,
                    email: user.email
                })
            })
            .catch((error) => {
                return JSON.stringify({ error: error.message })
            })
    }
)

export const createFirestoreUser = createAsyncThunk(
    'user/createFirestoreUser',
    async (user) => {
        try {
            const userData = { 
                type: user.type, 
                email: firebaseAuth.currentUser.email 
            }
            setDoc(doc(firestoreDB, 'users', firebaseAuth.currentUser.uid), userData)
            return JSON.stringify({
                ...userData,
                id: firebaseAuth.currentUser.uid
            })
        } catch (error) {
            return JSON.stringify({ error: error.message })
        }
    }
)

/**
 * Logs into Firebase Auth using email, password
 * Fetches user data from Firestore
 * Adds user/error to Redux store
 * @param {object} user - {email, password}
 * @return {JSON String} - { email, password, type } or { error }
 */
export const login = createAsyncThunk(
    'user/login',
    async (user) => {
        return signInWithEmailAndPassword(firebaseAuth, user.email, user.password)
            .then((response) => {
                return getDoc(doc(firestoreDB, 'users', response.user.uid))
            })
            .then((response) => {
                return JSON.stringify({ ...response.data(), id: response.id })
            })
            .catch((error) => {
                return JSON.stringify({ error: error.message })
            })
    }
)

/**
 * Logs into Firebase Auth using provider
 * Fetches user data from Firestore
 * Adds user/error to Redux store
 * @param {Firebase Auth Provider} provider
 * @return {JSON String} - { email, password, type } or { error }
 */
export const loginWithPopup = createAsyncThunk(
    'user/loginGooglePopup',
    async (provider) => {
        return signInWithPopup(firebaseAuth, provider)
            .then((response) => {
                return getDoc(doc(firestoreDB, 'users', response.user.uid))
            })
            .then((response) => {
                if(response.data()) {
                    return JSON.stringify({ ...response.data(), id: response.id })
                }
                else {
                    return JSON.stringify({ 
                        error: reduxGoogleUserNotInFirestore
                    });
                }
            })
            .catch((error) => {
                return JSON.stringify({ error: error.message })
            });
    }
)

export const logout = createAsyncThunk(
    'user/logout',
    async () => {
        signOut(firebaseAuth)
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser: (state) => {
            if(state != initialState) {
                return initialState
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.fulfilled, (state, action) => JSON.parse(action.payload))
            .addCase(createFirestoreUser.fulfilled, (state, action) => JSON.parse(action.payload))
            .addCase(login.fulfilled, (state, action) => JSON.parse(action.payload))
            .addCase(loginWithPopup.fulfilled, (state, action) => JSON.parse(action.payload))
            .addCase(logout.fulfilled, () => {
                return initialState
            })
    }
})

export const selectUser = (state) => state.user

export const userReducer = userSlice.reducer