import { Tabs, Tab } from "react-bootstrap";
import CustomerOrders from "./customer-orders/CustomerOrders";
import { selectUser } from "../../setup/redux/slices/userSlice";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Navigate } from "react-router-dom";

const CustomerDashboard = () => {
    const user = useSelector(selectUser)

    return (
        <div>
            {/* If user is not logged in, navigate to login. */}
            {!user.hasOwnProperty('id') && <Navigate to={'/login'}/>}
            {
                user.hasOwnProperty('id') &&
                <Tabs defaultActiveKey="orders" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="orders" title="Orders">
                        <CustomerOrders />
                    </Tab>
                </Tabs>
            }
        </div>
    )
}

export default CustomerDashboard