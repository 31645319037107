import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { 
    persistReducer, 
    persistStore, 
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER 
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userReducer } from "./slices/userSlice"
// import { orderReducer } from "./slices/orderSlice";
// import { shippingReducer} from "./slices/shippingSlice";
// import { billingReducer } from "./slices/billingSlice";
// import { productReducer } from "./slices/productSlice";

const persistConfig = {
    key: 'root',
    storage
}

const rootReducer = combineReducers({
    user: userReducer,
    // order: orderReducer,
    // shipping: shippingReducer,
    // billing: billingReducer,
    // product: productReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export const persistor = persistStore(store)