import { useEffect, useState } from "react"
import { getDocsArray, getSortedDocsObj } from "../../../setup/firebase/firestoreDB"
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap"

const MATERIAL_TYPES = ['metals', 'textiles', 'plastics', 'other']
const FINISH_TYPES = ['injectionMolding', 'CNC']

const CustomizationNavbar = (props) => {
    const setProductField = (field, value) => {
        props.setProductField(field, value)
    }

    const [materials, setMaterials] = useState()
    const [processes, setProcesses] = useState()
    const [finishes, setFinishes] = useState()
    const [testing, setTesting] = useState()
    const [packaging, setPackaging] = useState()
    const [components, setComponents] = useState()

    const loadCollection = async (colName, setter) => {
        getDocsArray(colName)
            .then((response) => {
                setter(response)})
            .catch(() => setter([]))
    }

    const loadCollectionSort = async (colName, sortField, typesArray, setter) => {
        getSortedDocsObj(colName, sortField, typesArray)
            .then((response) => setter(response))
            .catch(() => setter(null))
    }

    useEffect(() =>{
        loadCollectionSort('materials', 'type', MATERIAL_TYPES, setMaterials)
        loadCollection('processes', setProcesses)
        loadCollectionSort('finishes', 'type', FINISH_TYPES, setFinishes)  
        loadCollection('testing', setTesting)
        loadCollection('packaging', setPackaging)
        loadCollection('components', setComponents)
    }, [])

    return (
        <Navbar className="color-nav" expand="lg">
            <Container fluid>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                    <Nav className="flex-grow-1 justify-content-evenly">
                        {/* Dropdowns for materials, processes, finishes, etc. populated using arrays */}
                        <NavDropdown title="Materials">
                            { 
                                materials &&
                                Object.keys(materials).map((type) => (
                                    <div key={type}>
                                        <NavDropdown title={type} drop="end">
                                            { 
                                                materials[type].map((material) => (
                                                    <div key={material.id}>
                                                        <NavDropdown.Item onClick={() => setProductField('material', material)}>
                                                            {material.name}
                                                        </NavDropdown.Item>
                                                    </div>
                                                ))
                                            }
                                        </NavDropdown>  
                                    </div>
                                ))
                            }
                        </NavDropdown>
                        {
                            processes &&
                            <NavDropdown title="Processes">
                                {
                                    processes.map((process) => (
                                        <div key={process.id}>
                                            <NavDropdown.Item onClick={() => setProductField('processes', process)}>
                                                {process.name}
                                            </NavDropdown.Item>
                                        </div>
                                    ))
                                }
                            </NavDropdown>
                        }
                        <NavDropdown title="Finishes">
                            { 
                                finishes &&
                                Object.keys(finishes).map((type) => (
                                    <div key={type}>
                                        <NavDropdown title={type} drop="end">
                                            { 
                                                finishes[type].map((finish) => (
                                                    <div key={finish.id}>
                                                        <NavDropdown.Item onClick={() => setProductField('finish', finish)}>
                                                            {finish.name}
                                                        </NavDropdown.Item>
                                                    </div>
                                                ))
                                            }
                                        </NavDropdown>  
                                    </div>
                                ))
                            }
                        </NavDropdown>
                        <NavDropdown title="Testing">
                            { 
                                testing &&
                                testing.map((testing) => (
                                <div key={testing.id}>
                                    <NavDropdown.Item onClick={() => setProductField('testing', testing)}>
                                        {testing.name}
                                    </NavDropdown.Item> 
                                </div>
                            ))}
                        </NavDropdown>
                        <NavDropdown title="Packaging">
                            { 
                                packaging &&
                                packaging.map((packaging) => (
                                <div key={packaging.id}>
                                    <NavDropdown.Item onClick={() => setProductField('packaging', packaging)}>
                                        {packaging.name}
                                    </NavDropdown.Item> 
                                </div>
                            ))}
                        </NavDropdown>
                        <NavDropdown title="Components">
                            { 
                                components &&
                                components.map((component) => (
                                <div key={component.id}>
                                    <NavDropdown.Item onClick={() => setProductField('components', component)}>
                                        {component.name}
                                    </NavDropdown.Item> 
                                </div>
                            ))}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default CustomizationNavbar